import React from 'react'
import ReactLivestream from 'react-livestream'
import LivestreamOffline from './LivestreamOffline'

const Livestream = () => {
    const twitchId = process.env.REACT_APP_TWITCH_API_KEY
    const twitchUsername = process.env.REACT_APP_TWITCH_USERNAME

    return (
        <div>
            <ReactLivestream
                platform='twitch'
                twitchClientId={twitchId}
                twitchUserName={twitchUsername}
                offlineComponent={<LivestreamOffline />} />
        </div>
    )
}

export default Livestream
