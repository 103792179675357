import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './Home/Home'
import Livestream from './Livestream/Livestream'
import './App.css'

const App = () => (
  <div className='center'>
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route path="/livestream" component={Livestream} />
    </Switch>
  </div> 
)

export default App
